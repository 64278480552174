import { memo } from 'react'
import { useLocation, useMatch } from 'react-router-dom'

import { SubHeaderButton, SubHeaderButtonGroup } from 'main/components/layout/shared/sub-header/sub-header-button'
import { useLanguage } from 'main/services/hooks'
import { ConfigModel } from 'main/data-access'

export type DisplayType = 'dashboard' | 'page' | 'list' | 'map' | 'table'

export const ViewNavigation = memo(() => {
  const { t } = useLanguage('runbook', { keyPrefix: 'runbookViewToggle' })
  const location = useLocation()
  const isAngularDeveloperRunbook = ConfigModel.useIsFeatureEnabled('angular_developer_runbook')
  const displayMatch = useMatch('/app/:accountId/runbooks/:runbookId/:runbookVersionId/tasks/:displayType')
  const currentPage = displayMatch?.params.displayType as DisplayType | undefined
  const accountId = displayMatch?.params.accountId
  const runbookId = displayMatch?.params.runbookId
  const runbookVersionId = displayMatch?.params.runbookVersionId

  return (
    <SubHeaderButtonGroup>
      <SubHeaderButton
        label={'List'}
        active={currentPage === 'list'}
        navTo={`/app/${accountId}/runbooks/${runbookId}/${runbookVersionId}/tasks/list${location.search}`}
      />
      {isAngularDeveloperRunbook && (
        // Note: no active prop is passed as this page will be fully rendered in Angular
        <SubHeaderButton
          label={t('list')}
          navTo={`/app/${accountId}/runbooks/${runbookId}/${runbookVersionId}/tasks/angular-list${location.search}`}
          pill={{ label: 'Angular', size: 'small', color: 'feature-orange' }}
        />
      )}
      <SubHeaderButton
        label={t('nodemap')}
        active={currentPage === 'map'}
        navTo={`/app/${accountId}/runbooks/${runbookId}/${runbookVersionId}/tasks/map${location.search}`}
      />
      <SubHeaderButton
        label={t('table')}
        active={currentPage === 'table'}
        navTo={`/app/${accountId}/runbooks/${runbookId}/${runbookVersionId}/tasks/table${location.search}`}
      />
    </SubHeaderButtonGroup>
  )
})
