import { LoadingPanel, ToastContainer } from '@cutover/react-ui'
import * as Sentry from '@sentry/react'
import { eventManager } from 'event-manager'
import Cookies from 'js-cookie'
import { Component, ErrorInfo, Suspense, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useLocation, Navigate, Outlet, Route, Routes, useMatch, useNavigate, useSearchParams } from 'react-router-dom'
import { useUnmount } from 'react-use'
import ResizeObserver from 'resize-observer-polyfill'
import { AppProps as SSPAAppProps } from 'single-spa'
import { AppTypes } from 'single-spa/app-types'

import {
  useIsAppPageMatch,
  useIsConnectSettingsMatch,
  useIsDatasourcesMatch,
  useIsIntegrationConnectionsMatch,
  useIsMyTasksMatch,
  useIsReactMigrationRunbookMatch,
  useIsReactMigrationDefaultFilterRoute,
  useIsReactMigrationWorkspaceMatch,
  useIsRoleMappingsMatch,
  useIsSystemParametersMatch,
  useSamlMatch,
  useIsRunbookTypesMatch
} from 'single-spa/route-matchers'
import { omit } from 'lodash'

import ReactAppErrorScreen from './react-app-error'
import { LoginPage } from 'main/components/authentication/login-page'
import { PasswordForgotPage } from 'main/components/authentication/password-forgot-page'
import { PasswordResetPage } from 'main/components/authentication/password-reset-page'
import { Session } from 'main/components/authentication/session'
import { UserVerifyPage } from 'main/components/authentication/user-verify-page'
import { Sidebar } from 'main/components/layout'
import { SidebarNavProvider } from 'main/components/layout/sidebar/nav-context'
import { MyWorkLayout } from 'main/components/my-work/layout'
import { MyTasksTable } from 'main/components/my-work/pages/my-tasks-table/table'
import { AppsPage } from 'main/components/apps/apps-page'
import { RunbookModals } from 'main/components/runbook/modals/runbook-modals'
import { Dashboard } from 'main/components/runbook/pages/dashboard/dashboard'
import { TaskList } from 'main/components/runbook/pages/task-list/task-list'
import { TaskListSnippetConnectors } from 'main/components/runbook/pages/task-list-snippet-connectors'
import { RunbookLayout } from 'main/components/runbook/runbook-layout'
import { ConnectSettingsLayout } from 'main/components/settings/connect-settings/connect-settings-layout'
import { ConnectSettingsList } from 'main/components/settings/connect-settings/connect-settings-list'
import { DataSourcesLayout } from 'main/components/settings/data-sources/data-sources-layout'
import { DataSourcesList } from 'main/components/settings/data-sources/data-sources-list'
import { InstanceSettingsPage } from 'main/components/settings/instance-settings/instance-settings-page'
import { IntegrationSettingsLayout } from 'main/components/settings/integration-settings/integration-settings-layout'
import { IntegrationSettingsPage } from 'main/components/settings/integration-settings/integration-settings-page'
import { RoleMappingsLayout } from 'main/components/settings/saml-configurations/role-mappings/role-mappings-layout'
import { RoleMappingsList } from 'main/components/settings/saml-configurations/role-mappings/role-mappings-list'
import { SamlConfigurationListPage } from 'main/components/settings/saml-configurations/saml-configuration-list-page'
import { SamlConfigurationsLayout } from 'main/components/settings/saml-configurations/saml-configurations-layout'
import { FilterProvider } from 'main/components/shared/filter/filter-provider'
import { SupportAndAnalytics } from 'main/components/support-and-analytics/support-and-analytics'
import { useHeap } from 'main/components/support-and-analytics/use-heap'
import { RunbooksList, RunbooksTable, CentralTeamsPage } from 'main/components/workspace/pages'
import { RunbooksDashboard } from 'main/components/workspace/pages/runbooks-dashboard'
import { RunbookListPage } from 'main/components/workspace/pages/runbooks-list/runbook-list-page'
import { RunbooksPageConnectors } from 'main/components/workspace/pages/runbooks-list/runbooks-page-connectors'
import { RunbooksTimelineContainer } from 'main/components/workspace/pages/runbooks-timeline/runbooks-timeline-container'
import { WorkspaceLayout } from 'main/components/workspace/workspace-layout'
import { MAIN_HEADER_HEIGHT, MAIN_NAV_WIDTH, ReactAppConnector } from 'main/connectors/react-app-connector'
import { ReactAppConnectors } from 'main/connectors/react-app-connectors'
import { AppProviders } from 'main/context/app-providers'
import { NavigationEventEmitter } from 'main/recoil/shared/recoil-sync/navigation-event-emitter'
import {
  RecoilSyncComponent,
  buildRecoilSyncURI,
  encodeReservedCharacters
} from 'main/recoil/shared/recoil-sync/recoil-sync-component'
import { AccountDataProvider } from 'main/services/api/data-providers/account/account-data-provider'
import { MyTasksDataProvider } from 'main/services/api/data-providers/my-tasks/my-tasks-data-provider'
import { WorkspaceDataProvider } from 'main/services/api/data-providers/workspace/workspace-data-provider'
import {
  AppsResourceChannelSubscriberReact,
  AppsResourceChannelSubscriberAngular,
  CutoverConnectChannelProvider
} from 'main/services/api/websocket-providers'
import { AppsUserChannelSubscriber, UserChannelProvider } from 'main/services/api/websocket-providers/'
import { useGetValidateToken } from 'main/services/queries/use-get-validate-token'
import { isEmpty } from 'lodash'
import { getSavedViewQueryString } from 'main/components/shared/filter/filter-params'
import { SystemParametersLayout } from 'main/components/settings/system-parameters/system-parameters-layout'
import { SystemParametersListPage } from 'main/components/settings/system-parameters/system-parameters-list-page'
import { NodeMap } from 'main/components/runbook/pages/node-map/node-map'
import { useAppliedFilters } from 'main/recoil/data-access'
import { AccountChannelSubscriber } from 'main/components/workspace/workspace-data/account-channel-subscriber'
import { UserChannelSubscriber } from 'main/components/authentication/user-channel-subscriber'
import { RunbookDataRequestsAndChannelSubscriber } from 'main/components/runbook/runbook-data/runbook-data-requests-and-channel-subscriber'
import { ActiveRunbookModel, CommentModel, SavedFilterModel, ConfigModel } from 'main/data-access'
import { RunbookSubHeaderContextProvider } from 'main/components/runbook/runbook-sub-header/use-runbook-sub-header'
import { TaskTable } from 'main/components/runbook/pages/table/task-table'
import { RunbookTypesLayout } from 'main/components/settings/runbook-types/runbook-types-layout'
import { RunbookTypesListPage } from 'main/components/settings/runbook-types/runbook-types-list-page'
import { useRunbookAPIInterceptor } from 'main/services/api/interceptors'
import { Users } from 'main/components/runbook/pages/users/users'

if (!window.ResizeObserver) window.ResizeObserver = ResizeObserver

type ReactAppProps = SSPAAppProps & {
  name: AppTypes.React
}

type ReactAppState = { hasError: boolean; errorInfo: string; appName: string }

export class ReactApp extends Component<ReactAppProps, ReactAppState> {
  constructor(props: ReactAppProps) {
    super(props)
    this.state = { hasError: false, errorInfo: '', appName: props.name }
  }

  public static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorInfo: error }
  }

  /**
   * Retrieves and sets the URL in a session storage item, prior to mounting the react router.
   * Required when opening a URL in a new tab (e.g. when clicking on a link in an external document).
   */
  public componentDidMount(): void {
    const path = location.href.split('/#')[1]
    if (path.includes('app')) {
      sessionStorage.setItem('reactLoginRedirect', path)
    }
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setContext('info', {
        componentStack: info.componentStack
      })
      Sentry.captureException(error)
    })
    this.setState({ hasError: true })
  }

  public render() {
    if (this.state.hasError) {
      return <ReactAppErrorScreen />
    }
    return (
      <AppProviders fullScreen>
        <RecoilSyncWrapper>
          <Routing />
        </RecoilSyncWrapper>
        <ToastContainer />
      </AppProviders>
    )
  }
}

const RecoilSyncWrapper = ({ children }: any) => {
  const isReactRunbookEnabled = ConfigModel.useIsFeatureEnabled('react_runbook')

  if (isReactRunbookEnabled) {
    // this needs to be high up in the tree as possible because it syncs the route derived atoms for
    // resources ids: account, runbook, runbook version
    return <RecoilSyncComponent>{children}</RecoilSyncComponent>
  } else {
    return <>{children}</>
  }
}

// Enables tracing for performance monitoring via Sentry
const TracedRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const Routing = () => {
  const queryClient = useQueryClient()
  const { loadHeap } = useHeap()

  const isReactLoginEnabled = ConfigModel.useIsFeatureEnabled('react_login')
  const isReactRunbookEnabled = ConfigModel.useIsFeatureEnabled('react_runbook')
  const isReactWorkspaceEnabled = ConfigModel.useIsFeatureEnabled('react_workspace')
  const isMyTasksEnabled = ConfigModel.useIsFeatureEnabled('my_tasks')

  useEffect(() => {
    loadHeap()
  }, [])

  useUnmount(() => queryClient.clear())

  const routes = (
    <TracedRoutes>
      <Route path="/*" element={<EmptyContent />}>
        {isReactLoginEnabled && (
          <>
            <Route path="login" element={<TryAuthenticationOrLogin />} />
            <Route path="forgotpassword" element={<PasswordForgotPage />} />
            <Route path="userverify" element={<UserVerifyPage />} />
            <Route path="resetpassword" element={<PasswordResetPage />} />
          </>
        )}
        {/* need to define all possible route segments under /app, even if we don't have any new react content yet, so
        that the :accountId param is only set when appropriate */}
        <Route path="app/*" element={<AuthenticatedContent />}>
          <Route path="my-cutover" element={<EmptyContent />} />
          {isMyTasksEnabled && (
            <Route path="my-tasks" element={<MyWorkContent />}>
              <Route path="*" element={<MyTasksTable />} />
            </Route>
          )}
          <Route
            path="integration_action_item/:integrationActionItemId"
            element={
              <AppsUserChannelSubscriber>
                <AppsPage />
              </AppsUserChannelSubscriber>
            }
          />
          <Route path="settings/*">
            <Route path="instancesettings" element={<InstanceSettingsPage />} />
            <Route path="integration_connections" element={<IntegrationSettingsLayout />}>
              <Route index element={<IntegrationSettingsPage />} />
            </Route>
            <Route path="cutover-connect" element={<ConnectSettingsLayout />}>
              <Route
                index
                element={
                  <CutoverConnectChannelProvider>
                    <ConnectSettingsList />
                  </CutoverConnectChannelProvider>
                }
              />
            </Route>
            <Route path="data-sources" element={<DataSourcesLayout />}>
              <Route index element={<DataSourcesList />} />
            </Route>
            <Route path="saml" element={<SamlConfigurationsLayout />}>
              <Route index element={<SamlConfigurationListPage />} />
            </Route>
            <Route path="role_mappings" element={<RoleMappingsLayout />}>
              <Route index element={<RoleMappingsList />} />
            </Route>
            <Route path="system-parameters" element={<SystemParametersLayout />}>
              <Route index element={<SystemParametersListPage />} />
            </Route>
            <Route
              path="runbook-types"
              element={
                <RecoilSyncComponent>
                  <RunbookTypesLayout />
                </RecoilSyncComponent>
              }
            >
              <Route index element={<RunbookTypesListPage />} />
            </Route>
            <Route path="*" element={<EmptyContent />} />
          </Route>
          <Route path="access/*" element={<EmptyContent />} />
          <Route path="help/*" element={<EmptyContent />} />
          <Route path=":accountId/*" element={<AccountContent />}>
            <Route path="runbooks/*" element={<WorkspaceContent />}>
              <Route path="list" element={isReactWorkspaceEnabled ? <RunbooksList /> : <RunbookListPage />} />
              <Route path="table" element={isReactWorkspaceEnabled ? <RunbooksTable /> : <Outlet />} />
              <Route path="timeline" element={isReactWorkspaceEnabled ? <RunbooksTimelineContainer /> : <Outlet />} />
              <Route
                path="dashboard/:dashboardId"
                element={isReactWorkspaceEnabled ? <RunbooksDashboard /> : <Outlet />}
              />
              <Route path=":runbookId/:runbookVersionId/*" element={<RunbookContent />}>
                <Route path="activity" element={<EmptyContent />} />
                <Route path="tasks/*" element={<EmptyContent />}>
                  {/* move any of the routes above that are nested under tasks into the wildcard path below to share the
                  data context for account, runbook and tasks */}
                  <Route
                    path="list"
                    element={
                      isReactRunbookEnabled ? (
                        <Suspense fallback={<div></div>}>
                          <MigrationTaskListContent />
                        </Suspense>
                      ) : (
                        <TaskListSnippetConnectors />
                      )
                    }
                  >
                    <Route index element={isReactRunbookEnabled ? <TaskList /> : <EmptyContent />} />
                  </Route>
                  <Route path="map" element={isReactRunbookEnabled ? <NodeMap /> : <EmptyContent />} />
                  <Route path="table" element={isReactRunbookEnabled ? <TaskTable /> : <EmptyContent />} />
                  <Route
                    path="dashboard/:dashboardId"
                    element={
                      isReactRunbookEnabled ? (
                        <Suspense fallback={<LoadingPanel />}>
                          <Dashboard />
                        </Suspense>
                      ) : (
                        <EmptyContent />
                      )
                    }
                  />
                </Route>
                <Route path="page/*" element={<EmptyContent />}>
                  <Route
                    path="content/:dashboardId"
                    element={
                      isReactRunbookEnabled ? (
                        <Suspense fallback={<LoadingPanel />}>
                          <Dashboard />
                        </Suspense>
                      ) : (
                        <EmptyContent />
                      )
                    }
                  />
                </Route>
                <Route path="*" element={<EmptyContent />} />
                {isReactRunbookEnabled && (
                  <Route
                    path="users"
                    element={
                      <Suspense fallback={<LoadingPanel />}>
                        <Users />
                      </Suspense>
                    }
                  />
                )}
              </Route>
            </Route>
            <Route path="settings/teams" element={isReactWorkspaceEnabled ? <CentralTeamsPage /> : <EmptyContent />} />
            <Route path="*" element={<EmptyContent />} />
          </Route>
          <Route path="*" element={<EmptyContent />} />
        </Route>
        <Route index element={<EmptyContent />} />
      </Route>
    </TracedRoutes>
  )

  if (isReactRunbookEnabled) {
    // This is a component so that we can conditionally render hooks. The component calls
    // `useLocation` in order to trigger syncronization efects for recoil state. We want
    // this to include navigation that originates from outside the react nested routes.
    // However we only want to emit those events if the react runbook recoil state is used.
    return <NavigationEventEmitter>{routes}</NavigationEventEmitter>
  } else {
    return routes
  }
}

const BumpToPreviousRoute = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(-1)
  })

  return <></>
}

export const EmptyContent = () => {
  return <Outlet />
}

export const WorkspaceContent = () => {
  const isReactMigrationWorkspaceMatch = useIsReactMigrationWorkspaceMatch()

  return <>{isReactMigrationWorkspaceMatch ? <Outlet /> : <RunbooksPageConnectors />}</>
}

const AccountContentInner = () => {
  const isReactMigrationWorkspaceMatch = useIsReactMigrationWorkspaceMatch()

  if (isReactMigrationWorkspaceMatch) {
    return <WorkspaceLayout />
  } else {
    return <Outlet />
  }
}

export const AccountContent = () => (
  <FilterProvider>
    <AccountChannelSubscriber>
      <AccountDataProvider>
        <WorkspaceDataProvider>
          <AccountContentInner />
        </WorkspaceDataProvider>
      </AccountDataProvider>
    </AccountChannelSubscriber>
  </FilterProvider>
)

const MyWorkContent = () => {
  return (
    <FilterProvider>
      <MyTasksDataProvider>
        <MyWorkLayout />
      </MyTasksDataProvider>
    </FilterProvider>
  )
}

export const RunbookContent = ({ withWebsockets = true }: { withWebsockets?: boolean }) => {
  const isReactRunbookEnabled = ConfigModel.useIsFeatureEnabled('react_runbook')
  const isReactMigrationRunbookMatch = useIsReactMigrationRunbookMatch()
  const resetRunbookComments = CommentModel.useReloadSync()

  // Add runbook related API handling
  useRunbookAPIInterceptor()

  useEffect(() => {
    const refreshRunbookPageData = () => {
      window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'runbook-version' } }))
      window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'runbook' } }))
      window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'tasks' } }))
      resetRunbookComments()
    }
    if (isReactRunbookEnabled) {
      eventManager.on('refresh-runbook-page-data', refreshRunbookPageData)
    }

    return () => {
      eventManager.off('refresh-runbook-page-data', refreshRunbookPageData)
    }
  }, [])

  // full page skeleton loader has to be outside the channel subscribers because
  // they use runbook level recoil state
  return (
    <>
      {isReactMigrationRunbookMatch ? (
        <RunbookDataRequestsAndChannelSubscriber withWebsockets={withWebsockets}>
          <AppsUserChannelSubscriber>
            <AppsResourceChannelSubscriberReact>
              <UserChannelSubscriber>
                <RunbookSubHeaderContextProvider>
                  <RunbookLayout />
                  <RunbookModals />
                </RunbookSubHeaderContextProvider>
              </UserChannelSubscriber>
            </AppsResourceChannelSubscriberReact>
          </AppsUserChannelSubscriber>
        </RunbookDataRequestsAndChannelSubscriber>
      ) : (
        <Suspense>
          <AppsUserChannelSubscriber>
            <AppsResourceChannelSubscriberAngular>
              <RunbookModals />
              <Outlet />
            </AppsResourceChannelSubscriberAngular>
          </AppsUserChannelSubscriber>
        </Suspense>
      )}
    </>
  )
}

const MigrationTaskListContent = () => {
  const isTaskListMigrationMatch = useIsReactMigrationDefaultFilterRoute()

  const runbookId = ActiveRunbookModel.useId()
  const defaultSavedFilter = SavedFilterModel.useGetBy({ default: true })
  const appliedFilters = useAppliedFilters()
  const { state, pathname, key } = useLocation()

  const shouldNavigateToDefault =
    isTaskListMigrationMatch &&
    !!defaultSavedFilter &&
    !!defaultSavedFilter.query_string &&
    // location key is always "default" when clicking on the runbook list item link
    key === 'default' &&
    isEmpty(appliedFilters) &&
    !state?.activeFilterId

  if (!isTaskListMigrationMatch) {
    return <BumpToPreviousRoute />
  }

  if (shouldNavigateToDefault) {
    // TODO: dry up computing for saved filter --- organization in progress
    const searchObject = JSON.parse(defaultSavedFilter.query_string)
    const cleanedObject = omit(searchObject, [
      'accountId',
      'runbookId',
      'projectId',
      'runbookVersionId',
      'dashboardId',
      'disable_components',
      '_display',
      'accountSlug',
      'forceReload'
    ])

    if (cleanedObject.q) {
      cleanedObject.q = encodeReservedCharacters(cleanedObject.q)
    }
    if (cleanedObject.f) {
      cleanedObject.f = JSON.parse(encodeReservedCharacters(JSON.stringify(cleanedObject.f)))
    }
    const searchString = getSavedViewQueryString(cleanedObject)
    const search = buildRecoilSyncURI(searchString)

    return search === '?' || search === '' ? (
      <Outlet />
    ) : (
      <>
        <Outlet />
        <Navigate
          state={{
            runbookId,
            navDefault: true,
            overrideNavDefault: false
          }}
          to={{
            pathname,
            search
          }}
          replace
        />
      </>
    )
  } else {
    return <Outlet />
  }
}

export const TryAuthenticationOrLogin = () => {
  const [searchParams] = useSearchParams()
  const isSSOUser = searchParams.get('auth_type') === 'saml'
  return isSSOUser ? <SSOAuthentication /> : <LoginPage />
}

const SSOAuthentication = () => {
  const [searchParams] = useSearchParams()
  const [redirect] = useState(sessionStorage.getItem('reactLoginRedirect'))
  const navigate = useNavigate()

  if (!Cookies.get('auth_headers')) {
    Cookies.set('auth_headers', {
      'access-token': searchParams.get('auth_token'),
      'token-type': 'Bearer',
      client: searchParams.get('client_id'),
      expiry: searchParams.get('expiry'),
      uid: searchParams.get('uid')
    })
  }

  useGetValidateToken({
    onError: () => {
      Cookies.remove('auth_headers')
      navigate('/login')
    },
    onSuccess: user => {
      eventManager.emit('login', user)
      sessionStorage.removeItem('reactLoginRedirect')
      localStorage.removeItem('showRedirectMessage')
      navigate(redirect || '/app/my-cutover')
    }
  })

  return <Outlet />
}

export const AuthenticatedContent = () => {
  const isReactLoginEnabled = ConfigModel.useIsFeatureEnabled('react_login')
  const isReactWorkspaceEnabled = ConfigModel.useIsFeatureEnabled('react_workspace')
  const routeContextMatch = useMatch({ path: 'app/:context', end: false })
  const isViewGlobalSettings =
    routeContextMatch?.params.context && ['settings', 'access'].includes(routeContextMatch.params.context)
  const isMyTasksMatch = useIsMyTasksMatch()
  const isReactMigrationWorkspaceMatch = useIsReactMigrationWorkspaceMatch()
  const isReactRunbookMatch = useIsReactMigrationRunbookMatch()
  const isConnectSettingsMatch = useIsConnectSettingsMatch()
  const isSamlConfigurationsMatch = useSamlMatch()
  const isRoleMappingsMatch = useIsRoleMappingsMatch()
  const isDatasourcesMatch = useIsDatasourcesMatch()
  const isIntegrationConnectionMatch = useIsIntegrationConnectionsMatch()
  const isSystemParametersMatch = useIsSystemParametersMatch()
  const isRunbookTypesMatch = useIsRunbookTypesMatch()
  const isAppPage = useIsAppPageMatch()
  const fullScreenRoutes = ['integration_action_item']
  const mainWidth =
    routeContextMatch?.params.context && fullScreenRoutes.includes(routeContextMatch.params.context)
      ? 0
      : MAIN_NAV_WIDTH
  const mainHeaderHeight =
    routeContextMatch?.params.context && fullScreenRoutes.includes(routeContextMatch.params.context)
      ? 0
      : MAIN_HEADER_HEIGHT

  return (
    <Session>
      <UserChannelProvider>
        {isReactLoginEnabled && <SupportAndAnalytics />}
        {isReactWorkspaceEnabled && !isAppPage && (
          <SidebarNavProvider initialView={!!isViewGlobalSettings ? 'settings' : 'default'}>
            <Sidebar />
          </SidebarNavProvider>
        )}
        <ReactAppConnectors />
        {isReactMigrationWorkspaceMatch ||
        isReactRunbookMatch ||
        isMyTasksMatch ||
        isRoleMappingsMatch ||
        isDatasourcesMatch ||
        isConnectSettingsMatch ||
        isSamlConfigurationsMatch ||
        isIntegrationConnectionMatch ||
        isSystemParametersMatch ||
        isRunbookTypesMatch ? (
          <Outlet />
        ) : (
          <ReactAppConnector mainHeaderHeight={mainHeaderHeight} mainNavWidth={mainWidth} />
        )}
      </UserChannelProvider>
    </Session>
  )
}
