import { useCallback } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { RUNBOOK_MIGRATION_ROUTES } from 'main/../single-spa/route-matchers'

import { routes } from './routes'
import { ConfigModel } from 'main/data-access'

export const useRouting = () => {
  const toRunbook = useCallback(({ runbookId, ...params }: Parameters<typeof routes.toRunbook>[0]) => {
    return routes.toRunbook({
      runbookId,
      ...params
    })
  }, [])

  const toWorkspace = useCallback(({ accountSlug, templateType }: { accountSlug: string; templateType: any }) => {
    if (templateType === 'default') {
      return routes.toWorkspaceTemplates({ accountSlug })
    } else if (templateType === 'snippet') {
      return routes.toWorkspaceSnippets({ accountSlug })
    } else {
      return routes.toWorkspaceRunbooks({ accountSlug })
    }
  }, [])

  const toDashboard = useCallback((params: Parameters<typeof routes.toDashboard>[0]) => {
    return routes.toDashboard({ ...params })
  }, [])

  const toRunbookUsers = useCallback((params: Parameters<typeof routes.toRunbookUsers>[0]) => {
    return routes.toRunbookUsers({ ...params })
  }, [])

  return { toRunbook, toDashboard, toWorkspace, toRunbookUsers }
}

export const useIsOnReactRunbook = () => {
  const isReactRunbookEnabled = ConfigModel.useIsFeatureEnabled('react_runbook')
  const displayMatch = useMatch('/app/:accountId/runbooks/:runbookId/:runbookVersionId/tasks/:displayType/*')
  const usersMatch = useMatch({
    path: '/app/:accountSlug/runbooks/:runbookId/:runbookVersionId/:runbookPage'
  })
  const currentPage = displayMatch?.params.displayType || usersMatch?.params.runbookPage

  return !!(isReactRunbookEnabled && currentPage && RUNBOOK_MIGRATION_ROUTES.includes(currentPage))
}

// TODO: why are we getting the match outside the callback? investigate can use matchPath
export const useNavigateUpdateRunbookVersion = () => {
  const navigate = useNavigate()
  const match = useMatch('/app/:accountSlug/runbooks/:runbookId/:runbookVersionId/tasks/:displayType')

  return useCallback(
    (versionId: number) => {
      const runbookId = match?.params.runbookId as string
      const accountSlug = match?.params.accountSlug as string
      navigate(routes.toRunbook({ accountSlug, runbookId: parseInt(runbookId), versionId }))
    },
    [match?.params.accountSlug, match?.params.runbookId, navigate]
  )
}
