import { useNavigate, useParams } from 'react-router-dom'

import { colors, LegacyTableBase as Table, LegacyText as Text } from '@cutover/react-ui'
import { StreamRowItem } from './stream-row-item'
import { getStreamSummaryData, sortStreams, useStreamsSummary } from './use-stream-summary'
import { MrdDashboardWidget } from '../../account/mrd-dashboard-widget'
import { DashboardComponent, DashboardStreamData, GenericWidgetProps, StreamSummaryData } from '../../types'
import { serverQueryObjectToBrowserQueryString } from 'main/components/shared/filter/filter-params'

export const StreamSummaryWidget = ({ data, media }: GenericWidgetProps) => {
  return media === 'screen' ? <BrowserWidget data={data} /> : <EmailWidget data={data} />
}

const EmailWidget = ({
  data: { timezone, settings, runbook, tasks, filtered_tasks, streams, name }
}: {
  data: DashboardComponent
}) => {
  const taskData = filtered_tasks || tasks
  const runbookIsActive = runbook.stage !== 'planning'
  const mappedStreams = streams.map(stream => getStreamSummaryData(stream, taskData))
  const showSubstreams = Boolean(settings && settings.show_substreams)

  return (
    <MrdDashboardWidget
      fullWidth
      title={`${name || 'Stream Summary'} ${!showSubstreams ? '- Substreams omitted' : ''}`}
    >
      <WidgetContent
        streams={mappedStreams}
        runbookIsActive={runbookIsActive}
        showSubstreams={showSubstreams}
        timezone={timezone}
      />
    </MrdDashboardWidget>
  )
}

const BrowserWidget = ({
  data: { timezone, settings, runbook, tasks, filtered_tasks, task_filters, streams, name }
}: {
  data: DashboardComponent
}) => {
  const navigate = useNavigate()
  const { accountId, runbookId, runbookVersionId } = useParams()

  const runbookIsActive = runbook.stage !== 'planning'
  const taskData = filtered_tasks || tasks
  const mappedStreams = useStreamsSummary(streams, taskData)

  const showSubstreams = Boolean(settings && settings.show_substreams)

  const handleOnRowClick = (stream: StreamSummaryData) => {
    const queryString = serverQueryObjectToBrowserQueryString({
      queryObject: { ...task_filters, stream: [stream.internal_id] }
    })

    navigate(`/app/${accountId}/runbooks/${runbookId}/${runbookVersionId}/tasks/list${queryString}`)
  }

  return (
    <MrdDashboardWidget
      fullWidth
      title={`${name || 'Stream Summary'} ${!showSubstreams ? '- Substreams omitted' : ''}`}
    >
      <WidgetContent
        streams={mappedStreams}
        showSubstreams={showSubstreams}
        runbookIsActive={runbookIsActive}
        timezone={timezone}
        onRowClick={handleOnRowClick}
      />
    </MrdDashboardWidget>
  )
}

type WidgetContentProps = {
  streams: StreamSummaryData[]
  runbookIsActive: boolean
  timezone: string
  onRowClick?: (stream: StreamSummaryData) => void
  showSubstreams: boolean
}

const WidgetContent = ({ streams, runbookIsActive, timezone, showSubstreams, onRowClick }: WidgetContentProps) => {
  const linearStreamsForDisplay: StreamSummaryData[] = sortStreams(streams).reduce(
    (all: DashboardStreamData[], stream: DashboardStreamData) => {
      if (showSubstreams && stream.children.length > 0) {
        all.push(stream, ...stream.children)
      } else {
        all.push(stream)
      }
      return all
    },
    []
  )

  return (
    <>
      <Table width="100%">
        <Table.Head>
          <Table.Row>
            <Table.Header width="20%">Title</Table.Header>
            <Table.Header width="25%">Progress</Table.Header>
            <Table.Header width="20%">Start</Table.Header>
            <Table.Header width="20%">Finish</Table.Header>
            <Table.Header width="15%">Duration</Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {streams.length === 0 && (
            <Table.Row>
              <Table.Cell width="100%">
                <Text css="text-align: center" color={colors.primaryGrey}>
                  No streams found
                </Text>
              </Table.Cell>
            </Table.Row>
          )}
          {linearStreamsForDisplay.map((stream: StreamSummaryData) => (
            <StreamRowItem
              key={stream.id}
              stream={stream}
              runbookIsActive={runbookIsActive}
              parent={linearStreamsForDisplay.find(s => s.id === stream.parent_id)}
              timezone={timezone}
              onClick={onRowClick}
            />
          ))}
        </Table.Body>
      </Table>
    </>
  )
}
