import { atom, DefaultValue, selector, selectorFamily } from 'recoil'
import { string } from '@recoiljs/refine'
import queryString from 'query-string'
import { pick } from 'lodash'

import { urlHashSearchSyncEffect } from '../recoil-sync/recoil-url-hash-search-sync'
import {
  serverQueryObjectToBrowserQueryString,
  transformParam,
  transformQueryStringValueTypes
} from 'main/components/shared/filter/filter-params'
import { encodeReservedCharacters, handlePercentEncoding } from '../recoil-sync/recoil-sync-component'
import { UniversalFilterType } from 'main/recoil/data-access'
import { FILTER_DEFS } from 'main/components/shared/filter/filter-types'

export const searchAtom = atom({
  key: 'search', //  FIXME: crrently internal code in the recoil url hash file and component file depend on this key being "search".
  effects: [
    urlHashSearchSyncEffect({
      refine: string(),
      storeKey: 'search',
      history: 'push'
    })
  ]
})

export const nowDateAtom = atom({
  key: 'filters:now',
  default: 0
})

export const appliedFilterState = selector<UniversalFilterType>({
  key: 'filters:applied',
  get: ({ get }) => {
    const search = get(searchAtom)

    // TODO: revisit why f isn't working properly with the workspace parser
    // https://cutover.atlassian.net/browse/CFE-1440
    const { f, ...parsed } = queryString.parse(search, { parseNumbers: true })

    const allFilterKeys = Object.keys(FILTER_DEFS)
    const transformed = pick(transformQueryStringValueTypes(parsed as any), allFilterKeys) as UniversalFilterType

    if (f) {
      transformed.f = JSON.parse(decodeURIComponent(handlePercentEncoding(f as string)))
    }
    return transformed
  }
})

export const hasFiltersState = selector({
  key: 'filters:applied:has',
  get: ({ get }) => {
    const appliedFilters = get(appliedFilterState)
    return Object.keys(appliedFilters).length > 0
  }
})

export const filterCount = selector({
  key: 'filters:applied:count',
  get: ({ get }) => {
    const appliedFilters = get(appliedFilterState)
    const { f: cfFilters, ...restFilters } = appliedFilters
    const filterKeys = Object.keys(restFilters)
    const cfFiltersLength = cfFilters ? Object.keys(cfFilters).length : 0
    // NOTE: If at any point we have an additional URL param beyond "includeUsers"
    // that isn't a filter (e.g. sort direction), the return value will be incorrect.
    return filterKeys.includes('includeUsers')
      ? filterKeys.length - 1 + cfFiltersLength
      : filterKeys.length + cfFiltersLength
  }
})

export const filterSelector = selectorFamily<
  UniversalFilterType[keyof UniversalFilterType],
  { attribute: keyof UniversalFilterType }
>({
  key: 'filters:attribute',
  get:
    ({ attribute }) =>
    ({ get }) => {
      const appliedFilters = get(appliedFilterState)
      return appliedFilters[attribute]
    },
  set:
    ({ attribute }) =>
    ({ set, get }, newValue) => {
      const isDefaultValue = newValue instanceof DefaultValue
      const isEmpty =
        isDefaultValue ||
        newValue === null ||
        newValue === undefined ||
        (Array.isArray(newValue) && newValue.length === 0) ||
        (typeof newValue === 'object' && Object.keys(newValue).length === 0)

      const { f: customFieldsFilters, ...restAppliedFilters } = get(appliedFilterState)

      // @ts-ignore
      const { [attribute]: _, ...filtersWithout } = restAppliedFilters

      const searchWithoutCustomFields = serverQueryObjectToBrowserQueryString({
        queryObject: filtersWithout,
        // @ts-ignore
        overrides: attribute === 'f' ? {} : { [attribute]: transformParam(attribute, newValue) },
        defaults: getFilterDefaults(attribute, newValue),
        excludeKeys: [...(isEmpty ? [attribute] : []), ...getFilterExcludes(attribute, newValue)]
      })

      const nextCustomFieldsFilters = attribute === 'f' ? newValue : customFieldsFilters
      const isClearingCustomFieldFilter =
        attribute === 'f' &&
        (isDefaultValue ||
          (typeof newValue === 'object' && Object.keys(newValue).length === 0) ||
          newValue === null ||
          newValue == undefined)

      const string =
        isClearingCustomFieldFilter || !nextCustomFieldsFilters
          ? searchWithoutCustomFields
          : !searchWithoutCustomFields
          ? `?f=${encodeURI(JSON.stringify(nextCustomFieldsFilters))}`
          : searchWithoutCustomFields + `&f=${encodeURI(JSON.stringify(nextCustomFieldsFilters))}`

      set(searchAtom, filterStateEncoder(string.startsWith('?') ? string.substring(1) : string))
    }
})

// internal utils
///////////////////////////

const getFilterDefaults = (param: any, value: any) => {
  const defaults: any = {}

  switch (param) {
    case 'team':
      if (value && !(value.length === 1 && value[0] === 0)) {
        defaults['includeUsers'] = false
      }
  }

  return defaults
}

const getFilterExcludes = (param: any, value: any) => {
  const isDefaultValue = value instanceof DefaultValue

  const excludes: any[] = isDefaultValue || param === 'f' ? [param] : []

  switch (param) {
    case 'team':
      if (!value || !value.length || (value.length === 1 && value[0] === 0)) {
        excludes.push('includeUsers')
      }
  }

  return excludes
}

/**
 * Encodes special characters within the encoded URI, using the encoded double quotes '%22' for grouping.
 * @param input filter string
 * @returns encoded filter, including encoded special characters
 */
const filterStateEncoder = (input: string) => {
  const regex = /(%22)(.*?)\1/g

  const result = input.replace(regex, (_, group1, group2) => {
    const substitutedContent = encodeReservedCharacters(group2)
    return `${group1}${substitutedContent}${group1}`
  })

  return result
}

// TODO: Consolidate filter type definitions https://cutover.atlassian.net/browse/CFE-1439
