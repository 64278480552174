import styled, { css } from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'

import {
  Box,
  colors,
  DonutChart,
  DonutChartDatum,
  media as mediaCss,
  LegacyTableBase as Table
} from '@cutover/react-ui'
import { TasksByStageData, useCompletionByStageData } from './use-completion-by-stage-data'
import { MrdDashboardWidget } from '../../../widgets/account/mrd-dashboard-widget'
import { DashboardMediaType, GenericWidgetProps } from '../../types'
import {
  filterTaskData,
  useFilterTaskData,
  useSummaryStageComponentFilterData
} from 'main/components/dashboards/runbook-dashboard/use-task-filtering'
import { abbreviateNumber } from 'main/services/formatter'
import { serverQueryObjectToBrowserQueryString } from 'main/components/shared/filter/filter-params'

const GRAPH_COLOR_MAP = {
  skipped: colors.primaryLightest,
  abandoned: colors.primaryLight,
  complete: colors.primary,
  late: colors.warning,
  onTrack: colors.primaryGreyHoverBackgroundColor
}

type WidgetContentProps = {
  media: DashboardMediaType
  percentComplete: number
  tasksByStage: TasksByStageData
  onRowClick?: (item: any) => void
  name?: string
}

export const CompletionByStageWidget = (props: GenericWidgetProps) => {
  return props.media === 'screen' ? <BrowserWidget {...props} /> : <EmailWidget {...props} />
}

const BrowserWidget = ({ data, onClick }: GenericWidgetProps) => {
  const navigate = useNavigate()
  const { accountId, runbookId, runbookVersionId } = useParams()

  const filteredData = useFilterTaskData(data)
  const filtered = data.filtered_tasks || filteredData

  const { filteredTasks } = useSummaryStageComponentFilterData(filtered, data.filters)

  const { percentComplete, tasksByStage } = useCompletionByStageData(filteredTasks)

  const onRowClick = (data: any) => {
    onClick && onClick(data)

    const queryString = serverQueryObjectToBrowserQueryString({
      queryObject: { ...data.task_filters, stage: data.stage, ct: data.completionType }
    })

    navigate(`/app/${accountId}/runbooks/${runbookId}/${runbookVersionId}/tasks/list${queryString}`)
  }

  return (
    <WidgetContent
      percentComplete={percentComplete}
      tasksByStage={tasksByStage}
      media="screen"
      onRowClick={onRowClick}
      name={data.name}
    />
  )
}

const EmailWidget = ({ data, media }: GenericWidgetProps) => {
  const filteredData = filterTaskData(data)
  const filtered = data.filtered_tasks || filteredData

  const { percentComplete, tasksByStage } = useSummaryStageComponentFilterData(filtered, data.filters)

  return <WidgetContent percentComplete={percentComplete} tasksByStage={tasksByStage} media={media} name={data.name} />
}

type TableData = {
  name: string
  count: number
  late: number
  stage?: string
  completionType?: string
}

const WidgetContent = ({ percentComplete, tasksByStage, onRowClick, media, name }: WidgetContentProps) => {
  const { skipped, abandoned, completed, inProgress, notStartable, startable, late } = tasksByStage
  const onTrackDonutValue =
    inProgress.length +
    startable.length +
    notStartable.length -
    (late.inProgress.length + late.notStartable.length + late.startable.length)
  const lateDonutValue = late.inProgress.length + late.notStartable.length + late.startable.length

  const donutData: DonutChartDatum[] = [
    {
      id: `${skipped.length} Skipped`,
      label: `${skipped.length} Skipped`,
      name: 'Skipped',
      value: skipped.length,
      color: GRAPH_COLOR_MAP.skipped
    },
    {
      id: `${abandoned.length} Abandoned`,
      label: `${abandoned.length} Abandoned`,
      name: 'Abandoned',
      value: abandoned.length,
      color: GRAPH_COLOR_MAP.abandoned
    },
    {
      id: `${completed.length} Complete`,
      label: `${completed.length} Complete`,
      name: 'Complete',
      value: completed.length,
      color: GRAPH_COLOR_MAP.complete
    },
    {
      id: `${lateDonutValue} Running late`,
      label: `${lateDonutValue} Running late`,
      name: 'Running late',
      value: lateDonutValue,
      color: GRAPH_COLOR_MAP.late
    },
    {
      id: `${onTrackDonutValue} On track`,
      label: `${onTrackDonutValue} On track`,
      name: 'On track',
      value: onTrackDonutValue,
      color: GRAPH_COLOR_MAP.onTrack
    }
  ]

  const isEmpty =
    donutData.reduce((acc, d) => {
      return acc + d.value
    }, 0) === 0

  const tableData: TableData[] = [
    {
      name: 'Complete (Skipped)',
      count: skipped.length,
      late: 0,
      stage: 'complete',
      completionType: 'complete_skipped'
    },
    {
      name: 'Complete (Abandoned)',
      count: abandoned.length,
      late: 0,
      stage: 'complete',
      completionType: 'complete_abandoned'
    },
    {
      name: 'Complete',
      count: completed.length,
      late: late.complete.length,
      stage: 'complete',
      completionType: 'complete_normal'
    },
    {
      name: 'In Progress',
      count: inProgress.length,
      late: late.inProgress.length,
      stage: 'in-progress',
      completionType: 'complete_normal'
    },
    {
      name: 'Startable',
      count: startable.length,
      late: late.startable.length,
      stage: 'startable',
      completionType: 'complete_normal'
    },
    {
      name: 'Not yet startable',
      count: notStartable.length,
      late: late.notStartable.length,
      stage: 'default',
      completionType: 'complete_normal'
    },
    {
      name: 'Totals',
      count:
        completed.length +
        inProgress.length +
        startable.length +
        notStartable.length +
        skipped.length +
        abandoned.length,
      late: late.inProgress.length + late.notStartable.length + late.startable.length
    }
  ]

  const skippedORAbandoned = (data: TableData): Boolean => {
    if (data.name === 'Complete (Skipped)' && data.count === 0) {
      return false
    }

    if (data.name === 'Complete (Abandoned)' && data.count === 0) {
      return false
    }

    return true
  }
  const donutHeight = 175

  const handleRowClick = (data: any) => {
    onRowClick && onRowClick(data)
  }

  return (
    <MrdDashboardWidget fullWidth title={name || 'Completion By Stage'}>
      <Box direction={media === 'email' ? 'column' : 'row'} wrap justify="between">
        <ContentContainer media={media}>
          <DonutContainer>
            {isEmpty ? (
              <EmptyChart data={donutData} height={donutHeight} />
            ) : (
              <DonutChart
                data={donutData}
                withKey
                centerValue={`${percentComplete}%`}
                height={donutHeight}
                fontFamily="Roboto, sans-serif"
              />
            )}
          </DonutContainer>
        </ContentContainer>
        <ContentContainer media={media}>
          <Table
            width="100%"
            css={`
              padding-left: 16px;
            `}
          >
            <Table.Head>
              <Table.Row>
                <Table.Header width="50%"></Table.Header>
                <Table.Header width="25%">Total</Table.Header>
                <Table.Header width="25%" highlight={colors.warningBg}>
                  Late
                </Table.Header>
              </Table.Row>
            </Table.Head>

            <Table.Body>
              {tableData.filter(skippedORAbandoned).map(d => (
                <Table.Row
                  key={d.name}
                  onClick={() => handleRowClick({ stage: d.stage, completionType: d.completionType })}
                  css="cursor: pointer;"
                >
                  <Table.Cell width="50%">{d.name}</Table.Cell>
                  <Table.Cell width="25%">{abbreviateNumber(d.count)}</Table.Cell>
                  <Table.Cell width="25%" highlight={colors.warningBg} css="align-self: stretch;">
                    {/* Late is a live representation so irrelevant for completed, skipped and abandoned tasks  */}
                    {['Complete', 'Complete (Skipped)', 'Complete (Abandoned)'].includes(d.name) ? '-' : d.late}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </ContentContainer>
      </Box>
    </MrdDashboardWidget>
  )
}

const EmptyChart = ({ data, height }: { data: DonutChartDatum[]; height: number }) => {
  const emptyData = data.map(d => ({
    ...d,
    color: colors.primaryGreyHoverBackgroundColor,
    value: 100 / data.length
  }))

  // this is dependent on the donut chart layer order
  return (
    <>
      <Box
        css={`
          position: absolute;
          svg > g > g:first-child {
            visibility: hidden;
          }
        `}
      >
        <DonutChart data={data} withKey height={height} />
      </Box>
      <Box
        css={`
          position: absolute;
          svg > g > g:nth-child(2) {
            visibility: hidden;
          }
        `}
      >
        <DonutChart data={emptyData} segmentGap={3} withKey centerValue="0%" height={height} />
      </Box>
    </>
  )
}

const ContentContainer = styled(Box)<{ media: GenericWidgetProps['media'] }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: ${({ media }) => (media === 'email' ? '10px' : '0')};
  min-width: ${({ media }) => (media === 'email' ? 'auto' : '310px')};
  width: ${({ media }) => (media === 'email' ? '100%' : '49%')};

  ${mediaCss.sm(css`
    flex-direction: column;
    width: 100%;
    min-width: auto;
  `)}
`

const DonutContainer = styled(Box)<{ media?: GenericWidgetProps['media'] }>`
  display: flex;
  position: relative;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
  min-height: 200px;
  align-items: center;

  ${mediaCss.sm(css`
    width: 100%;
  `)}
`
